import { permissions, customerPermissions } from "@mssi/pssp-shared";
import Customer from "../types/customer";
import HomePage from "./home";
import { NavItem } from "types/nav-item";
import customerHasCapability from "shared/customer-has-capability";
import hasPermission from "../shared/has-permission";
import { lazy } from "react";
import useToolsEnablersVisibility from "shared/use-tools-enablers-visibility";
import { useCustomerHasContracts, useHasCurrentCustomerCompletedContractSetup } from "../redux/combo-hooks";
import { useCurrentCustomerITSMID } from "../redux/current-customer-hooks";
import { useCurrentUserIsInternal } from "../redux/auth-hooks";

const ContractsPage = lazy(() => import("./contracts"));
const CustomersPage = lazy(() => import("./customers"));
const DashboardsPage = lazy(() => import("./dashboards"));
const OrdersPage = lazy(() => import("./orders"));
const ProfilePage = lazy(() => import("./profile"));
const RolesPage = lazy(() => import("./roles"));
const ReportsPage = lazy(() => import("./reports"));
const ToolsPage = lazy(() => import("./tools"));
const UsersPage = lazy(() => import("./users"));
const UserCatalogPage = lazy(() => import("./user-catalog"));
const FeaturesPage = lazy(() => import("./features"));
const ManageCachePage = lazy(() => import("./manage-cache"));
const BundlesPage = lazy(() => import("./catalog/bundles"));
const DevicesPage = lazy(() => import("./devices"));
const PeripheralPage = lazy(() => import("./peripheral"));
const SoftwaresPage = lazy(() => import("./softwares"));
const WhatsNewPage = lazy(() => import("./whats-new"));
const GetSupportPage = lazy(() => import("./get-support"));
const SupportAccountTeam = lazy(() => import("./support-account-team"));
const SupportTicketsPage = lazy(() => import("./support-tickets"));
const ServiceRequestPage = lazy(() => import("./support-service-request"));
const LearnMorePage = lazy(() => import("./support-learn-more"));
const AuditsPage = lazy(() => import("./audits"));
const EmailConfig = lazy(() => import("./email-config"));
const AccountTeam = lazy(() => import("./account-team"));
const EmailProblemsTable = lazy(() => import("./email-problems"));

export const canUseMenuItem = (permissions: string[], customer: Customer, item: NavItem) => {
  const permissionsOK = !item.permissions || hasPermission(...item.permissions)({ persona: { permissions } });
  const capabilitiesOK = !item.capabilities || customerHasCapability(item.capabilities)(customer);

  return permissionsOK && capabilitiesOK;
};

const useMenuList = (): NavItem[] => {
  const isToolsAndEnablersVisible = useToolsEnablersVisibility();
  const isCatalogSetUp = useHasCurrentCustomerCompletedContractSetup();
  const itsmId = useCurrentCustomerITSMID();
  const hasContracts = useCustomerHasContracts();
  const userIsInternal = useCurrentUserIsInternal();
  const hideContracts = !userIsInternal && !hasContracts;

  return [
    {
      key: "home",
      label: "home.title",
      path: "/",
      icon: "home",
      component: HomePage,
    },
    {
      key: "orders",
      label: "orders.title",
      path: "/orders",
      icon: "order-management",
      permissions: [permissions.canViewOrders, permissions.canViewMyOrders],
      capabilities: [customerPermissions.contractualOrdering],
      component: OrdersPage,
      customerBased: true,
    },
    {
      key: "devices",
      label: "devices.dashboard",
      icon: "desktop",
      permissions: [permissions.canListDevices, permissions.canListMyDevices],
      customerBased: true,
      hide: !itsmId,
      subMenu: [
        {
          key: "devices",
          label: "devices.dashboard",
          path: "/devices",
          icon: "desktop",
          component: DevicesPage,
          customerBased: true,
        },
        {
          key: "peripherals",
          label: "peripheral.title",
          path: "/peripherals",
          icon: "desktop",
          component: PeripheralPage,
          customerBased: true,
        },
        {
          key: "software",
          label: "software.title",
          path: "/software",
          icon: "desktop",
          component: SoftwaresPage,
          customerBased: true,
        },
      ],
    },
    {
      key: "support",
      label: "caserequest.type.portal.support",
      icon: "support",
      permissions: [permissions.canAccessSupportPage, permissions.canAccessServiceRequests],
      subMenu: [
        {
          key: "getSupport",
          label: "getSupport.label",
          path: "/get-support",
          icon: "support",
          component: GetSupportPage,
          customerBased: true,
          permissions: [permissions.canAccessSupportPage, permissions.canAccessServiceRequests],
        },
        {
          key: "supportTickets",
          label: "supportTickets.label",
          path: "/support-tickets",
          icon: "logs",
          component: SupportTicketsPage,
          customerBased: true,
          permissions: [permissions.canAccessSupportPage],
        },
        {
          key: "serviceRequests",
          label: "serviceRequests.label",
          path: "/service-requests",
          icon: "request",
          component: ServiceRequestPage,
          customerBased: true,
          permissions: [permissions.canAccessServiceRequests],
        },
        {
          key: "accountTeam,",
          label: "contract.accountTeam",
          path: "/support-account-team",
          icon: "users",
          component: SupportAccountTeam,
          customerBased: true,
          permissions: [permissions.canAccessSupportPage],
        },
        {
          key: "whatsNew",
          label: "whatsnew.label",
          path: "/whats-new",
          icon: "megaphone",
          component: WhatsNewPage,
          permissions: [permissions.canAccessSupportPage],
        },
        {
          key: "learnMore",
          label: "tile.learnmore",
          path: "/learn-more",
          icon: "self-help",
          component: LearnMorePage,
          permissions: [permissions.canViewInternalTrainingDocumentSupport, permissions.canViewExternalTrainingDocumentSupport],
        },
      ],
    },
    {
      key: "catalog",
      label: "browseCatalog",
      icon: "catalog",
      permissions: [permissions.canViewBundleCatalog],
      customerBased: true,
      hide: !isCatalogSetUp,
      subMenu: [
        {
          key: "bundles",
          label: "bundles",
          path: "/bundles",
          icon: "inventory",
          permissions: [permissions.canViewBundleCatalog],
          component: BundlesPage,
          customerBased: true,
        },
      ],
    },
    {
      key: "contracts",
      label: "contract.title_plural",
      path: "/contracts",
      icon: "notes",
      permissions: [permissions.canListContracts],
      component: ContractsPage,
      customerBased: true,
      hide: hideContracts,
    },
    {
      key: "customers",
      label: "customer.title_plural",
      path: "/customers",
      icon: "company",
      permissions: [permissions.canViewCustomers],
      component: CustomersPage,
    },
    {
      key: "usersSection",
      label: "user.title_plural",
      icon: "users",
      permissions: [permissions.canManageUsers],
      subMenu: [
        {
          key: "users",
          label: "user.title_plural",
          path: "/users",
          icon: "users",
          permissions: [permissions.canManageUsers],
          component: UsersPage,
        },
        {
          key: "userCatalog",
          label: "user.catalog",
          path: "/user-catalog",
          icon: "organization",
          permissions: [permissions.canManageUserCatalog],
          component: UserCatalogPage,
          customerBased: true,
        },
      ],
    },
    {
      key: "reports",
      label: "reports.title",
      path: "/reports",
      icon: "reports",
      permissions: [permissions.canViewInternalReports, permissions.canViewExternalReports, permissions.canViewSystemAdminReports],
      component: ReportsPage,
      customerBased: true,
    },
    {
      key: "dashboards",
      label: "dashboard.title",
      path: "/dashboard",
      icon: "dashboard",
      permissions: [permissions.canViewDashboards, permissions.canManageDashboards],
      capabilities: [customerPermissions.canAccessDashboard],
      component: DashboardsPage,
      customerBased: true,
    },
    {
      key: "tools",
      label: "toolsandenablers",
      path: "/tools",
      icon: "configure",
      component: ToolsPage,
      hide: !isToolsAndEnablersVisible,
    },
    {
      key: "roles",
      label: "roles",
      path: "/roles",
      icon: "users",
      permissions: [permissions.canManagePersonas],
      component: RolesPage,
    },
    {
      key: "profile",
      label: "profile.title",
      path: "/profile",
      icon: "user",
      component: ProfilePage,
      hide: true,
    },
    {
      key: "system-operations",
      label: "systemOperations",
      icon: "configure",
      permissions: [permissions.canManageFeatures, permissions.canManageCache, permissions.canViewAudits, permissions.canManageGlobalEmailConfig, permissions.canManageGlobalAccountInfo],
      subMenu: [
        {
          key: "features",
          label: "featureflags",
          path: "/features",
          icon: "user-interface",
          permissions: [permissions.canManageFeatures],
          component: FeaturesPage,
        },
        {
          key: "cache",
          label: "manageCache",
          path: "/manage-cache",
          icon: "database",
          permissions: [permissions.canManageCache],
          component: ManageCachePage,
        },
        {
          key: "audits",
          label: "audits",
          path: "/audits",
          icon: "logs",
          permissions: [permissions.canViewAudits],
          component: AuditsPage,
        },
        {
          key: "emailconfig",
          label: "emailconfig",
          path: "/email-configuration",
          icon: "email",
          permissions: [permissions.canManageGlobalEmailConfig],
          component: EmailConfig,
        },
        {
          key: "accountteam",
          label: "contract.accountTeam",
          path: "/account-team",
          icon: "users",
          permissions: [permissions.canManageGlobalAccountInfo],
          component: AccountTeam,
        },
        {
          key: "emailProblems",
          label: "emailProblems",
          path: "/email-problems",
          icon: "incidents",
          component: EmailProblemsTable,
        },
      ],
    },
  ];
};

export default useMenuList;
